@import "~@/assets/scss/variables.scss";







































































































































































































































































































































.button-icon {
  font-size: 0.9em;
  color: #6d6d6d;
  cursor: pointer;
  transition: all .1s ease-in-out;
}

.button-icon:hover {
  transform: scale(1.05);
}

.button-icon:active {
  transform: scale(0.9);
}

fieldset.form-col {
  border: thin solid rgb(233, 236, 239);
  border-radius: 5px;
}

fieldset.form-col legend {
  font-size: 0.95em;
  padding-right: 1px;
  padding-left: 1px;
  width: auto;
  margin: 0;
  color: rgb(195, 195, 195);
}

.daq-link-info {
  font-size: 0.85rem;
}

.module-link {
  background: $msi-gray-100;
}

.module-link-error {
  color: $msi-danger;
  font-size: 0.75rem;
}

.daq-gateway-info {
  color: rgb(105, 105, 105);
  font-size: 0.8rem;
  background-color: #f5f5f5;
}
